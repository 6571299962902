<template>
  <b-form class="margin-bottom-16" @submit.prevent>
    <b-modal id="delete-product-modal" size="lg" no-close-on-backdrop>
      <template v-slot:modal-header>
        <b-container>
          <b-row>
            <b-col>
              <h3>{{ trans('products.delete_modal.title') }}</h3>
              <p class="description">
                {{ trans('products.delete_modal.desc') }}
              </p>
            </b-col>
          </b-row>
        </b-container>
      </template>

      <template v-slot:default>
        <b-container>
          <b-row>
            <b-col>
              <p class="mb-0">
                Er du sikker på at du vil slette
              </p>
              <p class="font-size-large">
                {{ productInfo.name }}
              </p>
              <p v-if="usedInProspects.length > 0">
                Dette produktet blir brukt i følgende prospekter:
              </p>
              <p v-else>
                Dette produktet er ikke i bruk i noen prospekter...
              </p>
              <ul>
                <li
                  v-for="p in usedInProspects"
                  :key="'product' + p.varycode"
                >
                  <b-link
                    :to="p.projectUrl"
                  >
                    {{ p.projectName }} ({{ p.title }})
                  </b-link>
                </li>
              </ul>
            </b-col>
          </b-row>
        </b-container>
      </template>

      <template v-slot:modal-footer="{ cancel }">
        <b-button
          variant="light"
          size="md"
          class="mr-auto padding-left-20 padding-right-20 border-danger"
          :disabled="processing"
          @click="deleteProduct(true)"
        >
          <span v-if="loading.hard">
            <i class="fas fa-circle-notch fa-spin mr-2"></i><span>{{ trans('products.delete_modal.buttons.deleting') }}</span>
          </span>
          <span v-else>
            <i class="fas fa-exclamation-triangle mr-2 text-danger"></i><span>{{ trans('products.delete_modal.buttons.hard_delete') }}</span>
          </span>
        </b-button>
        <b-button
          variant="light"
          size="md"
          class="padding-left-20 padding-right-20"
          :disabled="processing"
          @click="cancel()"
        >
          {{ trans('global.cancel') }}
        </b-button>
        <b-button
          variant="danger"
          size="md"
          class="padding-left-20 padding-right-20"
          :disabled="processing"
          @click="deleteProduct(false)"
        >
          <span v-if="loading.soft">
            <i class="fas fa-circle-notch fa-spin mr-2"></i><span>{{ trans('products.delete_modal.buttons.deleting') }}</span>
          </span>
          <span v-else>
            {{ trans('products.delete_modal.buttons.soft_delete') }}
          </span>
        </b-button>
      </template>
    </b-modal>
  </b-form>
</template>

<script>
  import * as objectPath from 'object-path'
  import { mapState } from 'vuex'
  import Vue from 'vue'

  const fb = require('../../../../firebaseConfig')
  const varyConfig = require('../../../../varyConfig')

  export default {
    name: 'ModalAddProduct',
    props: {
      product: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        processing: false,
        loading: {
          hard: false,
          soft: false
        }
      }
    },
    computed: {
      ...mapState({
        prospects: state => state.prospects.prospects,
        projects: state => state.projects.projects
      }),
      productInfo () {
        return {
          name: objectPath.get(this.product, 'name', 'Ukjent produktnavn')
        }
      },
      usedInProspects () {
        const self = this
        const prospects = []

        self.prospects.forEach((prospect) => {
          let currentProspect = {}

          if ( // Check if the prospect has rooms
            prospect.rooms !== undefined &&
            typeof prospect.rooms === 'object' &&
            prospect.rooms !== null
          ) {
            Object.keys(prospect.rooms).map((roomKey) => {
              const products = prospect.rooms[roomKey].products
              if ( // Check if the room has products
                products !== undefined &&
                typeof products === 'object' &&
                products !== null
              ) {
                Object.keys(products).map((prodKey) => {
                  const prod = products[prodKey]
                  if (
                    prod !== undefined &&
                    typeof prod === 'object' &&
                    prod !== null
                  ) {
                    if (prod.parentId === self.product._id) {
                      // Create the prospect object if missing
                      if (Object.keys(currentProspect).length === 0) {
                        currentProspect = {
                          title: prospect.title,
                          varycode: prospect.varycode,
                          rooms: [],
                          prospectId: prospect._id,
                          roomKey,
                          productKey: prodKey
                        }
                      }

                      const proj = self.projects.find(project => project._id === prospect.projectId)

                      if (proj) {
                        currentProspect.projectName = proj.name
                        currentProspect.projectSlug = proj.slug
                        currentProspect.projectUrl = '/projects/' + proj.slug + '/sales/' + prospect.slug
                      } else {
                        currentProspect.projectName = null
                        currentProspect.projectSlug = null
                        currentProspect.projectUrl = null
                      }

                      currentProspect.rooms.push(prospect.rooms[roomKey].customname !== undefined
                        ? prospect.rooms[roomKey].customname
                        : prospect.rooms[roomKey].category !== undefined
                          ? this.trans('global.' + varyConfig.roomCategories[prospect.rooms[roomKey].category])
                          : '(rom mangler navn)'
                      )
                    }
                  }
                })
              }
            })
          }

          if (Object.keys(currentProspect).length > 0) prospects.push(currentProspect)
        })

        console.log('ProspectsData:', prospects)

        return prospects
      }
    },
    methods: {
      hideModal (id) {
        this.$root.$emit('bv::hide::modal', id)
      },
      async deleteProduct (hardDelete) {
        // SoftDelete:
        // - Mark product as deleted in all prospects where in use
        // - Remove linked-products if they exist
        // - Mark product as deleted in products-collection

        // HardDelete:
        // - Remove all data from all prospects where in use
        // - Remove linked-products if they exist
        // - Remove all renders from all prospects where in use
        // - Remove product-data from products-collection
        // - Remove product-images from products-path

        // const self = this
        const status = {
          success: false,
          msg: hardDelete ? 'HardDeleted:' : 'SoftDeleted:',
          data: {}
        }

        this.processing = true
        Vue.set(this.loading, hardDelete ? 'hard' : 'soft', true)

        // Fake-delay:
        // await new Promise(resolve => setTimeout(() => resolve(), 100))

        const deletedFrom = []
        const storageRef = fb.storage.ref()

        // Remove product from all prospects where the product is in use
        for (const prospect of this.usedInProspects) {
          console.log('Removing product from prospect:', prospect.title)
          const prospectData = this.prospects.find(p => p.varycode === prospect.varycode)

          if (prospectData) {
            if (!hardDelete) {
              // Soft-delete - Mark item as deleted in all prospects where its in use
              console.log('SoftDeleteing the product from prospect: ', prospect.title)

              await fb.prospectsCollection.doc(prospect.prospectId).update({
                [`rooms.${prospect.roomKey}.products.${prospect.productKey}.deleted`]: true
              }).then(() => {
                console.log('Product was soft-deleted from ', prospect.title)
                deletedFrom.push(prospect.title)
              }).catch((err) => {
                console.log('Errors while deleting prospect from: ', prospect.title)
                console.log('ERROR:', err)
              })
            } else {
              // Hard-delete
              console.log('Hard-delete the product from: ', prospect.title)
              const renderImages = objectPath.get(prospectData, `rooms.${prospect.roomKey}.products.${prospect.productKey}.render`, {})
              const rendersPath = objectPath.get(renderImages, `${Object.keys(renderImages)[0]}.path`, undefined)
            
              // Delete render-folder
              if (rendersPath) {
                console.log('Found the renderPath: ', rendersPath)
                const rendersRef = storageRef.child(rendersPath)

                await rendersRef.listAll().then((listResults) => {
                  const promises = listResults.items.map((item) => {
                    return item.delete()
                  })

                  Promise.all(promises).then((res) => {
                    console.log('Done deleting all files')
                  }).catch((err) => {
                    console.log('Errors while deleting files:', err)
                  })
                })
              } else {
                console.log('Missing renderpath from prospect product data...')
              }

              // Delete the product from the prospects products object
              fb.prospectsCollection.doc(prospect.prospectId).update({
                [`rooms.${prospect.roomKey}.products.${prospect.productKey}`]: fb.firebase.firestore.FieldValue.delete()
              }).then(() => {
                deletedFrom.push(prospect.title)
              })
            }

            // Remove product from linked products (both softdelete and harddelete)
            const linkedProducts = objectPath.get(prospectData, `rooms.${prospect.roomKey}.products.${prospect.productKey}.links`)
            if (linkedProducts) {
              await this.removeLinkedProducts(prospect, prospectData).then(() => {
                console.log('Linked products was processed...')
              }).catch(() => {
                console.log('Errors when processing linked products')
              })
            }
          } else {
            console.log('Could not find the prospect data... skipping')
          }
        }

        status.msg += deletedFrom.join(', ') + '.'

        if (!hardDelete) {
          // Soft-delete the product from the products-collection
          await fb.productsCollection.doc(this.product._id).update({
            deleted: true
          }).then(() => {
            console.log('Product-document was updated and product marked as deleted from: ', deletedFrom)
            status.success = true
            status.msg += ' Marked as "deleted" in DB'
          }).catch((err) => {
            status.success = false
            status.data = err
            console.log('Errors when updating product from products-collection by ID')
          })
        } else {
          // Remove the product-images from storage
          const productImages = objectPath.get(this.product, 'image', {})
          const imagesPath = objectPath.get(productImages, `${Object.keys(productImages)[0]}.path`, undefined)
            
          // Delete render-folder
          if (imagesPath) {
            console.log('Found the renderPath: ', imagesPath)
            const rendersRef = storageRef.child(imagesPath)

            await rendersRef.listAll().then((listResults) => {
              const promises = listResults.items.map((item) => {
                return item.delete()
              })

              Promise.all(promises).then((res) => {
                console.log('Done deleting all product images')
              }).catch((err) => {
                console.log('Errors while deleting files:', err)
              })
            })
          } else {
            console.log('Missing renderpath...')
          }

          // Delete the document entirely from the products-collection
          await fb.productsCollection.doc(this.product._id).delete().then(() => {
            console.log('Product-document was removed entirely from the products-collection')
            status.success = true
            status.msg += ' Removed from DB!'
          }).catch((err) => {
            status.success = false
            status.data = err
            console.log('Errors when deleting product from products-collection by ID')
          })
        }

        this.processing = false
        Vue.set(this.loading, hardDelete ? 'hard' : 'soft', false)

        this.$bvToast.toast(status.msg, {
          title: this.trans(`products.delete_modal.success.${hardDelete ? 'hard' : 'soft'}`),
          variant: status.success ? 'success' : 'danger',
          solid: true,
          autoHideDelay: 10000
        })
        
        this.hideModal('delete-product-modal')
      },
      // Removes all linked products from the current product and this product from the linked
      // products - returns a promise
      removeLinkedProducts (prospect, prospectData) {
        // Prospect is from usedInProspects() and prospectData is from DB
        // prospect = {
        //   title: prospect.title,
        //   varycode: prospect.varycode,
        //   rooms: [],
        //   prospectId: prospect._id,
        //   roomKey,
        //   productKey: prodKey
        // }
        const links = {}
        const currentRoomKey = prospect.roomKey
        const productKey = prospect.productKey

        Object.keys(prospectData.rooms[currentRoomKey].products[productKey].links).forEach((roomKey) => {
          links[roomKey] = prospectData.rooms[currentRoomKey].products[productKey].links[roomKey]
        })

        const updateQuery = {}
        // Remove links from the current product
        updateQuery[`rooms.${currentRoomKey}.products.${productKey}.links`] = fb.firebase.firestore.FieldValue.delete()

        // Remove links from the linked products
        Object.keys(links).forEach((roomKey) => {
          updateQuery[`rooms.${roomKey}.products.${links[roomKey]}.links.${currentRoomKey}`] = fb.firebase.firestore.FieldValue.delete()
        })
        
        return new Promise((resolve, reject) => {
          fb.prospectsCollection.doc(prospectData._id).update(updateQuery).then(() => {
            console.log('[1] The linked products was removed from this product')
            console.log('[2] The product was removed from the linked products')
            resolve()
          }).catch((err) => {
            console.log('[Error] There was a error while removing the linked products:')
            console.log(err)
            reject(err)
          })
        })
      }
    }
  }
</script>
