<template>
  <b-container>
    <Heading type="h1" :title="trans('global.products')" />
    <div v-if="!dataReady" style="font-size: 30px; text-align: center; margin-top: 60px;">
      <i class="far fa-circle-notch fa-spin"></i> {{ trans('global.loading') }}...
    </div>
    <div v-else>
      <b-row style="margin-top: -33px;">
        <b-col>
          <p>{{ trans('products.all_index.showing') }} {{ numberOfProducts }} {{ trans('global.products').toLowerCase() }}</p>
        </b-col>
        <b-col class="text-right">
          <b-link
            @click="showUsage()"
          >
            <i v-if="showProductUsage" class="fas fa-sync fa-spin mr-2"></i>
            <span v-if="showProductUsage">Generer tallene...</span>
            <span v-else>Vis produktbruk</span>
          </b-link>
          <p style="font-size: 13px;">
            Viser {{ fetchedProducts }} av {{ numberOfProducts }}
          </p>
        </b-col>
      </b-row>
      <div v-if="batchDeleteProductIds.length > 0" style="position: fixed; bottom: 0; left: 0; right: 0; background-color: #eaeaea; z-index: 2; text-align: center; padding: 20px;">
        <b-btn
          @click="batchSoftDelete"
        >
          <span v-if="softdeleting">
            <i class="fas fa-circle-notch fa-spin mr-2"></i><span>{{ trans('products.delete_modal.buttons.deleting') }}</span>
          </span>
          <span v-else>
            Slett (soft) {{ batchDeleteProductIds.length }} produkter
          </span>
        </b-btn>
      </div>
      <b-row v-if="!products">
        <b-col>
          <div class="alert alert-warning">
            <i class="fas fa-exclamation-triangle padding-right-6"></i> {{ trans('products.no_products') }}
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12">
          <div class="vary-table">
            <div class="thead">
              <div class="row">
                <div class="col" style="flex: 0 0 50px;">
                  <i class="fas fa-check"></i>
                </div>
                <div class="col" style="flex: 0 0 200px;">
                  {{ trans('products.all_index.image') }}
                </div>
                <div class="col">
                  {{ trans('products.all_index.name') }}
                </div>
                <div class="col col-auto">
                  {{ trans('products.all_index.actions') }}
                </div>
              </div>
            </div>
            <div class="tbody">
              <div v-for="product in products" :key="product._id" class="row">
                <div class="col" style="flex: 0 0 50px;">
                  <b-form-checkbox
                    :id="`checkbox-${product._id}`"
                    v-model="batchDeleteProductIds"
                    :name="`checkbox_${product._id}`"
                    :value="product._id"
                  ></b-form-checkbox>
                </div>
                <div class="col" style="flex: 0 0 200px;">
                  <b-img v-if="productImage(product)" :src="productImage(product)"></b-img>
                  <div v-else style="display: flex; justify-content: center; align-items: center; background-color: #a6a6a6; opacity: 0.2; width: 100px; height: 100px; border-radius: 4px; color: #392a2a;">
                    <i class="far fa-unlink" style="vertical-align: center; font-size: 40px;"></i>
                  </div>
                </div>
                <div class="col">
                  <!-- <b-link :to="'/projects/' + project.slug + '/sales/' + prospect.slug"> -->
                  <b-link
                    class="font-size-large"
                    :to="'/products/edit/' + product.varycode"
                  >
                    {{ product.name }}
                  </b-link>
                </div>
                <div v-if="productCountIndex[product._id] !== undefined" class="col-1">
                  {{ productCountIndex[product._id] }}
                </div>
                <div class="col col-auto overflow-visible">
                  <b-dropdown size="md" variant="primary" no-caret right>
                    <template v-slot:button-content>
                      <i class="fas fa-caret-down"></i>
                    </template>
                    <b-dropdown-item @click="deleteProduct(product)">
                      {{ trans('global.delete') }}
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <ModalDeleteProduct :product="currentActiveProduct"></ModalDeleteProduct>
  </b-container>
</template>

<script>
  import { mapState } from 'vuex'
  import * as objectPath from 'object-path'
  import Heading from '../../components/Core/Heading'
  import ModalDeleteProduct from '../../components/Views/Products/Modals/DeleteProduct'

  const fb = require('../../firebaseConfig')

  export default {
    name: 'AllProducts',
    components: {
      Heading,
      ModalDeleteProduct
    },
    data () {
      return {
        loading: true,
        currentActiveProduct: {},
        fetchedProducts: 0,
        productCountIndex: {},
        showProductUsage: false,
        batchDeleteProductIds: [],
        softdeleting: false
      }
    },
    computed: {
      ...mapState({
        products: state => state.products.products,
        prospects: state => state.prospects.prospects
      }),
      dataReady () {
        return this.products !== undefined && this.products.length > 0
      },
      numberOfProducts () {
        return this.products.length
      },
      productSelected (productData) {
        console.log('ProductData: ', productData)
        return this.batchDeleteProductIds.includes('ok')
      }
    },
    beforeMount () {
      this.updateBreadcrumb([{ text: this.trans('global.products'), to: '/products' }, { text: this.trans('navigation.all_products'), to: '#' }])
    },
    methods: {
      productImage (product) {
        const imageKey = product.image !== undefined && Object.keys(product.image).length > 0 ? Object.keys(product.image)[Object.keys(product.image).length - 1] : false

        return imageKey ? process.env.VUE_APP_IMAGE_CDN_URL + '/' + product.image[imageKey].path + '/' + product.image[imageKey].file + '?fit=crop&w=100&h=100&mask=corners&corner-radius=5,5,5,5' : false
        // return this.products
      },
      deleteProduct (productData) {
        console.log('ProductData:', productData)
        this.currentActiveProduct = productData
        this.$bvModal.show('delete-product-modal')
      },
      async showUsage () {
        const self = this
        const productPromises = []
        this.showProductUsage = true
        this.fetchedProducts = 0
        await new Promise(resolve => setTimeout(() => resolve(), 100)) // Need to wait for showProductUsage update
        
        // Build an array of ALL product-promises
        for (const p of self.products) {
          productPromises.push(self.usedInProspects(p._id))
        }

        while (productPromises.length) {
          // 10 at a time
          await Promise.all(productPromises.splice(0, 10))
        }

        this.showProductUsage = false
      },
      usedInProspects (productId) {
        return new Promise((resolve) => {
          const self = this
          let count = 0
          self.prospects.forEach((prospect) => {
            if ( // Check if the prospect has rooms
              prospect.rooms !== undefined &&
              typeof prospect.rooms === 'object' &&
              prospect.rooms !== null
            ) {
              Object.keys(prospect.rooms).forEach((roomKey) => {
                const products = prospect.rooms[roomKey].products
                if ( // Check if the room has products
                  products !== undefined &&
                  typeof products === 'object' &&
                  products !== null
                ) {
                  Object.keys(products).forEach((prodKey) => {
                    const prod = products[prodKey]
                    if (
                      prod !== undefined &&
                      typeof prod === 'object' &&
                      prod !== null
                    ) {
                      if (prod.parentId === productId) {
                        count++
                      }
                    }
                  })
                }
              })
            }
          })

          self.productCountIndex[productId] = count
          self.fetchedProducts++
          resolve(count)
        })
      },
      async batchSoftDelete () {
        // SoftDelete:
        // - Mark product as deleted in all prospects where in use
        // - Remove linked-products if they exist
        // - Mark product as deleted in products-collection
        console.log('Deleting product_ids: ', this.batchDeleteProductIds)
        const status = {
          success: true,
          msg: '',
          data: {}
        }

        this.softdeleting = true // Enable anims on btn

        for (const pId of this.batchDeleteProductIds) {
          /**
           * Mark product as deleted in all prospects where in use
           */
          console.log('Current running for ID: ', pId)
          status.msg += 'Slettet: ' + pId
          const inUse = this.usedInProspectsForBatchDeletion(pId)
          // returns:  {title: 'HOL - Kjøkken', varycode: '000001133', prospectId: 'BfqyaUwEXb06v4lHOJE8', roomKey: '1', productKey: '154'}

          const removedFromProspects = []

          for (const prospect of inUse) {
            const prospectDocData = this.prospects.find(p => p.varycode === prospect.varycode)

            if (prospectDocData) {
              // Mark product as deleted
              await fb.prospectsCollection.doc(prospect.prospectId).update({
                [`rooms.${prospect.roomKey}.products.${prospect.productKey}.deleted`]: true
              }).then(() => {
                removedFromProspects.push(prospectDocData.title)
                console.log(`${pId} was successfully deleted from ${prospect.title}`)
              }).catch((err) => {
                console.log(`${pId} could not be removed from ${prospect.title}: `, err)
              })

              // Remove product from linked products (both softdelete and harddelete)
              const linkedProducts = objectPath.get(prospectDocData, `rooms.${prospect.roomKey}.products.${prospect.productKey}.links`)
              if (linkedProducts) {
                await this.removeLinkedProducts(prospect, prospectDocData).then(() => {
                  console.log('Linked products was processed...')
                }).catch(() => {
                  console.log('Errors when processing linked products')
                })
              }
            }
          }

          if (removedFromProspects.length > 0) {
            status.msg += ` fra prospektene: ${removedFromProspects.join(', ')}. `
          } else {
            status.msg += '. '
          }

          // Soft-delete the product from the products-collection
          await fb.productsCollection.doc(pId).update({
            deleted: true
          }).then(() => {
            status.success = true
            status.msg += 'Markert som "deleted" i DB. '
          }).catch((err) => {
            status.success = false
            status.data = err
            console.log('Errors when updating product from products-collection by ID', err)
          })
        }

        this.softdeleting = false
        this.batchDeleteProductIds = []

        this.$bvToast.toast(status.msg, {
          title: this.trans('products.delete_modal.success.soft'),
          variant: status.success ? 'success' : 'danger',
          solid: true,
          autoHideDelay: 10000
        })
      },
      /**
       * usedInProspectsForBatchDeletion ()
       * Modified usedInProspects function to comply with the needs when deleting multiple products
       */
      usedInProspectsForBatchDeletion (productId) {
        const self = this
        const prospects = []

        self.prospects.forEach((prospect) => {
          let currentProspect = {}

          if ( // Check if the prospect has rooms
            prospect.rooms !== undefined &&
            typeof prospect.rooms === 'object' &&
            prospect.rooms !== null
          ) {
            Object.keys(prospect.rooms).map((roomKey) => {
              const products = prospect.rooms[roomKey].products
              if ( // Check if the room has products
                products !== undefined &&
                typeof products === 'object' &&
                products !== null
              ) {
                Object.keys(products).map((prodKey) => {
                  const prod = products[prodKey]
                  if (
                    prod !== undefined &&
                    typeof prod === 'object' &&
                    prod !== null
                  ) {
                    if (prod.parentId === productId) {
                      // Create the prospect object if missing
                      if (Object.keys(currentProspect).length === 0) {
                        currentProspect = {
                          title: prospect.title,
                          varycode: prospect.varycode,
                          prospectId: prospect._id,
                          roomKey,
                          productKey: prodKey
                        }
                      }
                    }
                  }
                })
              }
            })
          }

          if (Object.keys(currentProspect).length > 0) prospects.push(currentProspect)
        })

        console.log('ProspectsData:', prospects)

        return prospects
      },
      // Removes all linked products from the current product and this product from the linked
      // products - returns a promise
      removeLinkedProducts (prospect, prospectData) {
        // Prospect is from usedInProspects() and prospectData is from DB
        // prospect = {
        //   title: prospect.title,
        //   varycode: prospect.varycode,
        //   rooms: [],
        //   prospectId: prospect._id,
        //   roomKey,
        //   productKey: prodKey
        // }
        const links = {}
        const currentRoomKey = prospect.roomKey
        const productKey = prospect.productKey

        Object.keys(prospectData.rooms[currentRoomKey].products[productKey].links).forEach((roomKey) => {
          links[roomKey] = prospectData.rooms[currentRoomKey].products[productKey].links[roomKey]
        })

        const updateQuery = {}
        // Remove links from the current product
        updateQuery[`rooms.${currentRoomKey}.products.${productKey}.links`] = fb.firebase.firestore.FieldValue.delete()

        // Remove links from the linked products
        Object.keys(links).forEach((roomKey) => {
          updateQuery[`rooms.${roomKey}.products.${links[roomKey]}.links.${currentRoomKey}`] = fb.firebase.firestore.FieldValue.delete()
        })
        
        return new Promise((resolve, reject) => {
          fb.prospectsCollection.doc(prospectData._id).update(updateQuery).then(() => {
            console.log('[1] The linked products was removed from this product')
            console.log('[2] The product was removed from the linked products')
            resolve()
          }).catch((err) => {
            console.log('[Error] There was a error while removing the linked products:')
            console.log(err)
            reject(err)
          })
        })
      }
    }
  }
</script>
